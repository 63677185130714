import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <Helmet>
      {process.env.NODE_ENV === 'production' && (
        <script>
          {`
          gtag('event', 'conversion', {
            'send_to': 'AW-825053665/hHWICIGY7YYBEOGjtYkD',
            'value': 1.0,
            'currency': 'USD'
          });
         `}
        </script>
      )}
    </Helmet>
    <div className="content">
      <Helmet>
        <title>Message Sent - {data.site.siteMetadata.title}</title>
      </Helmet>
      <h1>Message Sent</h1>
      <p>Your message has been sent. We will be in touch shortly.</p>
    </div>
  </Layout>
)

export const query = graphql`
  query Thanks {
    site {
      siteMetadata {
        title
      }
    }
  }
`
